import moment from "moment";
import { create } from "zustand";
import { ChatType } from "@/types";

export interface MessageStore {
  active: null | string;
  playing: boolean;
  conversations: Record<string, number>;
  channels: Record<string, number>;
  setUnreadCounts: (type: ChatType, id: string, addition?: number, reset?: boolean) => void;
  setChannelsUnreadCounts: (id: string, increase: boolean) => void;
  setConversationsUnreadCounts: (id: string, increase: boolean) => void;
  setActive: (id?: string) => void;
  setPlaying: (playing: boolean) => void;

  // For handle update
  updates: Record<string, number>;
  setUpdate: (id: string) => void;

  // For new message
  messages: Record<string, boolean>;
  setMessages: (id: string) => void;
  unsetMessages: (id: string) => void;

  focusedMsg: string | null | undefined;
  setFocusedMsg: (id: string | null | undefined) => void;

  focusedConversation: string | null | undefined;
  setFocusedConversation: (id: string | null | undefined) => void;
}

export const useMessage = create<MessageStore>((set) => ({
  active: null,
  playing: false,

  // For handle update
  updates: {},
  setUpdate: (id: string) => {
    set(({ updates }) => ({
      updates: { ...updates, [id]: moment().valueOf() }
    }));
  },

  // For new message
  messages: {},
  setMessages: (id: string) => {
    set(({ messages }) => ({
      messages: { ...messages, [id]: true }
    }));
  },
  unsetMessages: (id: string) => {
    set(({ messages }) => ({
      messages: { ...messages, [id]: false }
    }));
  },

  conversations: {},
  channels: {},
  // setUnreadCounts: (id: string, addition: number = 1) => {
  //   set(({ conversations }) => ({
  //     conversations: {
  //       ...conversations,
  //       [id]: addition === 0 ? 0 : (conversations[id] || 0) + addition
  //     }
  //   }),
  setUnreadCounts: (type, id, addition = 1, reset = false) => {
    set(({ conversations, channels }) => ({
      [type]: {
        ...(type === "conversations" ? conversations : channels),
        [id]: reset ? addition : addition === 0 ? 0 : conversations[id] || 0
      }
    }));
  },
  setChannelsUnreadCounts: (id: string, increase: boolean) => {
    set(({ channels }) => ({
      [ChatType.Channels]: {
        ...channels,
        [id]: increase ? (channels[id] ?? 0) + 1 : (channels[id] ?? 0) - 1,
      }
    }));
  },
  setConversationsUnreadCounts: (id: string, increase: boolean) => {
    set(({ conversations }) => ({
      [ChatType.Conversations]: {
        ...conversations,
        [id]: increase ? (conversations[id] ?? 0) + 1 : Math.max((conversations[id] ?? 0) - 1, 0),
      }
    }));
  },
  setActive: (id?: string) => set({ active: id || null }),
  setPlaying: (playing: boolean) => set({ playing }),

  focusedMsg: null,
  setFocusedMsg: (id: string | null | undefined) => set({ focusedMsg: id }),

  focusedConversation: null,
  setFocusedConversation: (id: string | null | undefined) => set({ focusedConversation: id })
}));
