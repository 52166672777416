import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey, getSocketKey } from "@key.ai/constants";
import { useSocket } from "@/providers/socket-provider";
import { queryUpdateUserStatus } from "@/lib/user";
import { PublishActionType } from "@key.ai/enum";
import { useUnreadsQuery } from "./use-unreads-query";
import { useMessage } from "@/stores/use-message-store";

type UserSocketProps = {
  userId: string;
  serverId?: string;
};

export const useUserSocket = ({ userId, serverId }: UserSocketProps) => {
  const queryClient = useQueryClient();
  const { watcher, socket } = useSocket();
  const { refetch } = useUnreadsQuery({ serverId, userId });
  const { conversations, channels } = useMessage((state) => state);
  const { setChannelsUnreadCounts, setConversationsUnreadCounts } = useMessage();
  useEffect(() => {
    if (!socket) {
      console.info("Sockets are not available to get realtime updates");
      return;
    }

    const key = getSocketKey("UPDATE_USER_STATUS");
    watcher?.(key, (payload: { isOnline: boolean; userId: string }) =>
      queryUpdateUserStatus(queryClient, payload)
    );

    const privateAddMember = getSocketKey("PRIVATE_ADD_MEMBER", {
      userId,
    });
    watcher?.(privateAddMember, async (data) => {
      await queryClient.refetchQueries({
        queryKey: [getQueryKey("PERSONAL_CONVERSATIONS")]
      });
      await queryClient.refetchQueries({
        queryKey: [getQueryKey("UNREADS", { userId })]
      });
    });

    const userEventKey = getSocketKey("USER_EVENT", {
      userId,
    });
    watcher?.(userEventKey, async (data) => {
      const actionTodo = async () => {
        if (data?.serverId === serverId) {
          await queryClient.refetchQueries({
            queryKey: [getQueryKey("CHANNELS", { serverId: data?.serverId })]
          });
          await queryClient.refetchQueries({
            queryKey: [getQueryKey("UNREADS", { userId })]
          });
        }
      }
      switch (data.actionType) {
        case PublishActionType.CHANNEL_ADD_MEMBER:
          actionTodo();
          break;
        case PublishActionType.CHANNEL_REMOVE_MEMBER:
          actionTodo();
          break;
        case PublishActionType.CREATE:
          if (data?.isChannel) {
            setChannelsUnreadCounts(data?.conversationId, true);
          } else {
            setConversationsUnreadCounts(data?.conversationId, true);
          }
          break;
        case PublishActionType.CHAT_DELETE_MESSAGE:
          if (data?.isChannel) {
            setChannelsUnreadCounts(data?.conversationId, false);
          } else {
            setConversationsUnreadCounts(data?.conversationId, false);
          }
          break;
      }
    });

  }, [queryClient, watcher, socket, userId, serverId, refetch, conversations, channels, setChannelsUnreadCounts, setConversationsUnreadCounts]);
};
