import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  queryDeleteMessage,
  queryUpdateMessage,
  queryUpdatePinned,
  queryUpsertMessage
} from "@/lib/conversation";
import { getQueryKey, getSocketKey } from "@key.ai/constants";
import moment from "moment";
import { useSocket } from "@/providers/socket-provider";
import { queryRemoveMemberFromConversation, queryUpdateConversationMembers } from "@/lib/channel";
import { PublishActionType } from "@key.ai/enum";

type ChatSocketProps = {
  conversationId: string;
  refetch?: any;
};

export const useChatSocket = ({ conversationId, refetch }: ChatSocketProps) => {
  const queryClient = useQueryClient();
  const { socket, watcher } = useSocket();
  useEffect(() => {
    if (!socket) {
      console.info("Sockets are not available to get realtime updates");
      return;
    }
    const queryKey = getQueryKey("CHAT_MESSAGES", { conversationId });
    const chatMessageKey = getSocketKey("CHAT_MESSAGES", { conversationId });

    const commonTransformer = (data: any) => {
      return {
        ...data,
        id: data.messageId,
        createdAt: moment(data.createdAt).toDate(),
        updatedAt: moment(data.updatedAt).toDate(),
      };
    }

    /* Watcher: Listener to receive new message */
    watcher?.(chatMessageKey, (data) => {
      switch (data?.actionType) {
        case PublishActionType.CREATE:
          queryUpsertMessage(queryClient, queryKey, commonTransformer(data), true);
          refetch && refetch();
          break;
        case PublishActionType.UPDATE:
          queryUpdateMessage(queryClient, queryKey, commonTransformer(data))
          break;
        case PublishActionType.CHAT_DELETE_MESSAGE:
          queryDeleteMessage(queryClient, queryKey, commonTransformer(data))
          break;
      }
    });

    /* Watcher: Listen to private member */
    const privateMemberKey = getSocketKey("PRIVATE_MEMBER", {
      conversationId,
    });
    watcher?.(privateMemberKey, async (data) => {
      switch (data?.actionType) {
        case PublishActionType.PRIVATE_ADD_MEMBER:
          queryUpdateConversationMembers(queryClient, data.conversationId)
          break;
        case PublishActionType.PRIVATE_UPDATE_MEMBER:
          queryUpdateConversationMembers(queryClient, data.conversationId)
          break;
        case PublishActionType.PRIVATE_REMOVE_MEMBER:
          queryRemoveMemberFromConversation(queryClient, data.conversationId)
          break;
      }
    });

  }, [socket, watcher]);
};

export const usePinnedSocket = ({ conversationId }: { conversationId: string }) => {
  const queryClient = useQueryClient();
  const { socket, watcher } = useSocket();
  useEffect(() => {
    if (!socket) {
      console.info("Sockets are not available to get realtime updates");
      return;
    }
    const updatePinKey = getSocketKey("UPDATE_PINNED", { conversationId });
    /* Watcher: Listener to receive update pinned */
    watcher?.(updatePinKey, (data) => {
      queryUpdatePinned(queryClient, data);
    });
  }, [socket, watcher]);
};
