import { useUnreadsQuery } from "@/hooks/use-unreads-query";
import { Fragment, useEffect } from "react";
import { useChatSocket } from "@/hooks/use-chat-socket";
import { useUserSocket } from "@/hooks/use-user-socket";
import { useMessage } from "@/stores/use-message-store";
import { ChatType } from "@/types";

export const UserSocketComponent = ({ userId, serverId }: { userId: string, serverId?: string }) => {
  useUserSocket({ userId, serverId });
  return <Fragment />;
};

export const UnreadsSocketComponent = ({
  serverId,
  userId
}: {
  serverId?: string;
  userId: string;
}) => {
  const { data: unreads, refetch } = useUnreadsQuery({ serverId, userId });

  if (!unreads) return <Fragment />;
  return (
    <Fragment>
      {Object.keys(unreads.channels || {}).map((id) => (
        <RealtimeListner
          conversationId={id}
          key={id}
          counts={unreads.channels[id]}
          type={ChatType.Channels}
          refetch={refetch}
        />
      ))}
      {Object.keys(unreads.conversations || {}).map((id) => (
        <RealtimeListner
          conversationId={id}
          key={id}
          counts={unreads.conversations[id]}
          type={ChatType.Conversations}
          refetch={refetch}
        />
      ))}
    </Fragment>
  );
};

const RealtimeListner = ({
  conversationId,
  counts,
  type,
  refetch
}: {
  conversationId: string;
  counts: number;
  type: ChatType;
  refetch: any;
}) => {
  const { setUnreadCounts } = useMessage();

  useEffect(() => {
    setUnreadCounts(type, conversationId, counts, true);
  }, [type, counts, conversationId, setUnreadCounts]);

  useChatSocket({ conversationId, refetch });
  return <Fragment />;
};
