import React from "react";

interface PlaceholderImageProps {
  className?: string;
  onClick?: () => void;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  className = "",
  onClick = () => {}
}) => {
  return (
    <div>
      <img
        src="/static/images/user.svg"
        alt="User placeholder"
        className={className}
        onClick={onClick}
      />
    </div>
  );
};

export default PlaceholderImage;
